import { MyKeysResponse } from "@/components/MyKeys";
import { API, queries, useAccountStore } from "@/utils";
import { useQuery } from "@tanstack/react-query";

const { pixKeys } = queries;

const getKeys = async () => {
  const { data } = await API.pix.get<MyKeysResponse[]>(
    "/api/v1/pix/Key/allKeys",
  );
  return data;
};

export const useGetKeys = () => {
  const { currentAccountId } = useAccountStore();
  return useQuery({
    queryKey: pixKeys.getKeys({ accountId: currentAccountId }),
    queryFn: getKeys,
    enabled: !!currentAccountId,
  });
};
