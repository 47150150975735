import { useState } from "react";
import {
  API,
  ClaimType,
  claimTypes,
  globalQueryClient,
  queries,
  useAccountStore,
  useDeviceFingerprint,
  Validator,
} from "@/utils";
import { formatKeyTypes, formatPhoneNumber, unmaskDocument } from "@/utils/pix";
import { Button, toast, Token } from "@hyperlocal/vital2";
import { Timer } from "./components/Timer";

const { pixKeys } = queries;

type KeyType = ReturnType<typeof Validator.checkPixKey>;
type PixKeyRequest = {
  type: string;
  token: string;
  key?: string;
  deviceId: string;
};

export interface FindKeyResponse {
  keyType: string;
  idEndToEnd: string;
  ispb: string;
  bankName: string;
  bankAccountNumber: string;
  bankBranchNumber: string;
  bankAccountType: string;
  personType: string;
  document: string;
  documentMask: string;
  name: string;
  key: string;
}

interface TokenSheetProps {
  isExternalCode: boolean;
  keyType: KeyType;
  newPixKey: string;
  goToNextStep: () => void;
  onSendToken: () => void;
  openClaimDrawer: (type: ClaimType) => void;
}

export const formattedKeyTypes: Partial<Record<KeyType, string>> = {
  "E-mail": "email",
  Celular: "PhoneNumber",
  CNPJ: "nationalRegistration",
  CPF: "nationalRegistration",
};

export function TokenSheet({
  isExternalCode,
  keyType,
  newPixKey,
  goToNextStep,
  onSendToken,
  openClaimDrawer,
}: TokenSheetProps) {
  const [totpCode, setTotpCode] = useState("");
  const { currentAccountId, account } = useAccountStore();
  const deviceFingerprint = useDeviceFingerprint();

  const pixType = formattedKeyTypes[keyType] ?? "evp";
  const formattedPixKey =
    keyType === "Celular"
      ? `+55${formatPhoneNumber(newPixKey)}`
      : formatKeyTypes(newPixKey, keyType);

  async function createPixKeyRequest() {
    const request: PixKeyRequest = {
      type: pixType,
      token: totpCode,
      deviceId: deviceFingerprint.deviceId,
      ...(isExternalCode ? { key: formattedPixKey } : {}),
    };

    await API.pix.post("/api/v1/pix/Key", request);
    goToNextStep();
  }

  async function handleVerification() {
    try {
      if (formattedPixKey) {
        const checkKeyExistsResponse = await API.pix.get<FindKeyResponse>(
          "/api/v1/pix/Key/find",
          {
            params: {
              Key: formattedPixKey.toLowerCase(),
              AccountId: currentAccountId,
            },
          },
        );
        const currentDocument =
          account?.personInfoResponse?.document ||
          account?.companyInfoResponse?.document;
        const accountDocument = unmaskDocument(currentDocument || "");
        const responseDocument = unmaskDocument(
          checkKeyExistsResponse.data.documentMask,
        );

        openClaimDrawer(
          accountDocument.includes(responseDocument)
            ? claimTypes.PORTABILITY
            : claimTypes.KEY_TRANSFER,
        );
      } else {
        await createPixKeyRequest();
      }
    } catch (error) {
      const friendlyMessage =
        error?.response?.data?.errors?.[0]?.friendlyMessage;

      if (friendlyMessage) {
        toast({
          variant: "error",
          title: friendlyMessage || "Algo deu errado",
        });
      }
    } finally {
      await globalQueryClient.invalidateQueries({
        queryKey: pixKeys.getKeys({ accountId: currentAccountId }),
      });
    }
  }

  const isDisabled = totpCode.length !== 6;

  const getContentText = () => {
    if (["E-mail", "Celular"].includes(keyType))
      return `Digite o código que foi enviado via ${keyType === "E-mail" ? keyType : "SMS"}:`;

    return (
      <>
        Digite o código do Token que foi <br /> enviado via{" "}
      </>
    );
  };

  return (
    <div className="flex flex-1 flex-col">
      <h2 className="mt-4 text-xs/md font-bold text-neutral-darkest mobile:text-xs">
        {getContentText()}

        {!isExternalCode && (
          <span className="text-primary-main">aplicativo autenticador</span>
        )}
      </h2>

      {isExternalCode && (
        <h5 className="font-inter text-[20px] font-bold leading-[30px] text-primary-main">
          {newPixKey}
        </h5>
      )}

      <div className="mt-8">
        <Token.Label className="mobile:text-x2s/default">
          Código de 6 caracteres
        </Token.Label>
        <Token.Root onValueChange={setTotpCode}>
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
        </Token.Root>
      </div>

      {["E-mail", "Celular"].includes(keyType) && (
        <Timer resendToken={() => onSendToken()} />
      )}

      <Button.Root
        variant="primary"
        className="mt-auto w-full justify-center"
        onClick={handleVerification}
        disabled={isDisabled}
      >
        Verificar
      </Button.Root>
    </div>
  );
}
