import { useGetKeys } from "@/api";
import { MyKeysResponse } from "@/components/MyKeys";
import EmptyKeysState from "../EmptyKeysState";
import MainKeyInfo from "../MainKeyInfo";
import { Feedback } from "./styles";

type DashboardProps = {
  handleOpenNewKeysDrawer: () => void;
};

export function getKeyInOrder(keys: MyKeysResponse[]) {
  const document = keys.find((key) => key.keyType === "NationalRegistration");
  if (document) return document;

  const number = keys.find((key) => key.keyType === "PhoneNumber");
  if (number) return number;

  const email = keys.find((key) => key.keyType === "Email");
  if (email) return email;

  return keys[0];
}

function Dashboard({ handleOpenNewKeysDrawer }: DashboardProps) {
  const { data: keys = [], isLoading } = useGetKeys();

  const key = getKeyInOrder(keys);

  if (isLoading) {
    return <Feedback>Carregando...</Feedback>;
  }

  if (key) {
    return <MainKeyInfo defaultKey={key} />;
  }

  return <EmptyKeysState handleOpenNewKeysDrawer={handleOpenNewKeysDrawer} />;
}

export default Dashboard;
