/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-extraneous-dependencies */
import {
  Validator,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import * as S from "./styles";

export const DeleteKeyContent = ({
  checkPixKey,
  createTokenRequest,
  selectedKey,
}) => {
  return (
    <S.WapperDrawerDelete>
      <p>Realmente deseja excluir permanentemente sua chave Pix?</p>
      <div>
        <Icon name="MoneyPix" />
        <p>
          <span>{checkPixKey(selectedKey)}</span>
          {selectedKey}
        </p>
      </div>
      <Button variant="secondary" onClick={createTokenRequest}>
        Continuar
      </Button>
    </S.WapperDrawerDelete>
  );
};

export const ReceivedClaimContent = ({
  keyInfo,
  getIconByKeyType,
  handleRequestedClaim,
  claimType,
}) => {
  const { checkPixKey } = Validator;
  const maxDate = new Date(keyInfo.claim.grantorDeadline).toLocaleDateString();
  const keyToDisplay = checkPixKey(
    keyInfo.type === "PHONE" ? keyInfo?.key.replace("+55", "") : keyInfo?.key,
  );
  const iconToDisplay = getIconByKeyType(keyInfo.keyType.toUpperCase());

  return (
    <S.WapperDrawerDelete>
      <div>
        <Icon name={iconToDisplay} />
        <p>
          <span>{keyToDisplay}</span>
          {keyInfo?.key}
        </p>
      </div>
      <p>
        Você recebeu uma {claimType} de sua chave Pix para outra instituição.
        Confirme esse pedido até {maxDate}.
      </p>
      <Button onClick={() => handleRequestedClaim("declineClaim", keyInfo)}>
        Manter chave na Hyperlocal
      </Button>
      <Button
        variant="secondary"
        onClick={() => handleRequestedClaim("acceptClaim", keyInfo)}
      >
        Fazer {claimType}
      </Button>
    </S.WapperDrawerDelete>
  );
};

export const RequestedClaimContent = ({
  getIconByKeyType,
  pixKey: key,
  claimType,
  handleRequestedClaim,
}) => {
  const { checkPixKey } = Validator;

  return (
    <S.WapperDrawerDelete>
      <div>
        <Icon name={getIconByKeyType(key.claim.keyType.toUpperCase())} />
        <p>
          <span>
            {key.claim.keyType === "PhoneNumber"
              ? checkPixKey(key?.claim.key.replace("+55", ""))
              : checkPixKey(key?.claim.key)}
          </span>
          {key?.claim.key}
        </p>
      </div>
      <p>
        Chave está em processo de {claimType}, você tem até 7 dias para aprovar
        no banco titular da chave Pix.
      </p>
      <Button
        variant="secondary"
        onClick={() => handleRequestedClaim("cancelClaim", key, claimType)}
      >
        Cancelar {claimType}
      </Button>
    </S.WapperDrawerDelete>
  );
};
