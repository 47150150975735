import { queries, requestBalance, useAccountStore } from "@/utils";
import { useQuery } from "@tanstack/react-query";

const { balanceKeys } = queries;

export function useBalance() {
  const { currentAccountId } = useAccountStore();

  return useQuery({
    queryKey: balanceKeys.getBalance({ accountId: currentAccountId }),
    queryFn: () => requestBalance(),
    staleTime: 1000 * 30, // 30 segundos (cache entre refetch's)
    select: (pixResponse) => pixResponse.Balance,
    refetchOnWindowFocus: true,
  });
}
