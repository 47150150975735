import { useEffect } from "react";
import { useGetKeys } from "@/api";
import { CheckLogo } from "@/assets/check-logo";
import {
  ClaimType,
  getCreateKey,
  Hooks,
  PF_PJ_MAX_KEYS,
  setHeader,
} from "@/utils";
import { Button, Divider } from "@hyperlocal/vital";

type SucessScreenProps = {
  claimType: ClaimType;
  goToSelectKeys: () => void;
  openSharedDrawer: () => void;
  backToMyKeysSucess: () => void;
};

export function SuccessScreen({
  claimType,
  goToSelectKeys,
  backToMyKeysSucess,
}: SucessScreenProps) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const request = getCreateKey();
  const { data: keys } = useGetKeys();

  const keysUnderLimit = keys.length < PF_PJ_MAX_KEYS;

  useEffect(() => {
    if (!isMobile) return;

    setHeader({
      title: `Cadastro Concluido`,
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: backToMyKeysSucess,
      },
    });
  }, [backToMyKeysSucess, isMobile]);

  const renderClaimTitle = () => {
    if (!request.claim) {
      return "Chave cadastrada";
    } else {
      return claimType === "KEY_TRANSFER"
        ? "Reivindicação solicitada"
        : "Portabilidade solicitada";
    }
  };

  const renderClaimDescription = () => {
    if (!request.claim) {
      return "Agora você já pode receber transações na sua Hyperlocal, compartilhe sua chavePix";
    } else {
      return `O processo de requisição de ${
        claimType === "KEY_TRANSFER" ? "reivindicação" : "portabilidade"
      } pode demorar até 7 dias.`;
    }
  };

  return (
    <div className="flex h-full flex-col items-center p-2 sm:p-0">
      <div className="mt-inset-xl flex flex-col gap-inset-lg">
        <CheckLogo />
        <div className="flex flex-col gap-inset-x3s">
          <h2 className="text-sm font-bold leading-9 text-neutral-darkest">
            {renderClaimTitle()}
          </h2>
        </div>
      </div>

      <div className="flex w-full flex-col py-inset-md">
        <Divider className="w-full bg-neutral-light" />
      </div>

      <div className="flex h-full flex-col gap-inset-x3s">
        <h3 className="text-x2s leading-6 text-neutral-darkest">
          {renderClaimTitle()}
        </h3>
        <p className="text-xs leading-7 text-neutral-dark">
          {renderClaimDescription()}
        </p>
      </div>

      <div className="flex w-full flex-col gap-inset-lg">
        <Button
          variant="secondary"
          onClick={goToSelectKeys}
          disabled={!keysUnderLimit}
          className="flex w-full items-center justify-center text-center"
        >
          Outra Chave
        </Button>
      </div>
    </div>
  );
}
