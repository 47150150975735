/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useState } from "react";
import { formattedKeyTypes, TokenSheet } from "@/components/NewKey/token";
import { usePixKeysContext } from "@/context/Pix";
import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import {
  API,
  getCreateKey,
  getPixKey,
  getPixRoutesData,
  getUser,
  Hooks,
  setCreateKey,
  setHeader,
  setTokenData,
  Validator,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Drawer } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useNavigate } from "react-router-dom";
import { usePrint } from "../../context/Print";
import { apis } from "../../services";
import { IGetKeys } from "../../services/types";
import PrintableQRCode from "../PrintableQRCode";
import SharedDrawer from "../ShareKey";
import ContentQR from "../ShareModalQR";
import { InsertKey } from "./InsertKey";
import { SelectKey } from "./SelectKey";
import * as S from "./styles";
import { SuccessScreen } from "./SuccessScreen";
import { FetchState } from "./types";

const PAGES = {
  SELECT_KEY: "SELECT_KEY",
  INSERT_KEY: "INSERT_KEY",
  SUCCESS_SCREEN: "SUCCESS_SCREEN",
} as const;

type Page = (typeof PAGES)[keyof typeof PAGES];

const tokenRequestClaimGuid = "09E8FC99-5B84-4ED4-B2E1-5EF4CC1D6469";
const tokenRequestGuid = "63D07325-4980-4ED9-9F6B-D7CFC985BA3F";

const formatKey = (key: string, type: string) => {
  const formatMap: Record<string, string> = {
    CPF: key?.replace(/[^0-9]/g, ""),
    CNPJ: key?.replace(/[^0-9]/g, ""),
    EVP: "",
    PHONE: key?.replace(/\D/g, ""),
  };

  return formatMap[type] || key;
};

export function formatPhoneNumber(phoneNumber) {
  // Remove todos os caracteres que não sejam dígitos
  return phoneNumber.replace(/\D/g, "");
}

const NewKey = () => {
  const { filterState } = usePixKeysContext();
  const isMobile = Hooks.useMediaQuery("mobile");
  const { checkPixKey } = Validator;
  const user = getUser();
  const { print } = usePrint();

  const navigate = useNavigate();
  const routes = getPixRoutesData();
  const [currentPage, setCurrentPage] = useState<Page>(PAGES.SELECT_KEY);
  const [claimType, setClaimType] = useState<
    "OWNERSHIP" | "PORTABILITY" | undefined
  >(undefined);
  const setRequest = setCreateKey;
  const request = getCreateKey();
  const keys = getPixKey();
  const [defaultKey, setDefaultKey] = useState<IGetKeys>(null);

  const [modal, setModal] = useState<{
    open: boolean;
    content?: JSX.Element;
  }>({
    open: false,
  });

  const [drawer, setDrawer] = useState<{
    title: string;
    open: boolean;
    content: JSX.Element;
    onClose?: () => void;
  } | null>(null);

  const [fetchState, updateFetchState] = useState<FetchState>({
    errorMessage: "",
    isFetching: false,
  });

  const newPixKey = filterState.newPixKey;
  const keyType = Validator.checkPixKey(filterState.newPixKey);
  const isExternalCode = ["E-mail", "Celular"].includes(keyType);

  const validateOwnershipOrPortability = useCallback(async () => {
    if (!request?.key) return;

    const accountId = user?.profiles?.Customers?.[0]?.guid_account;
    const userId = user?.login?.guid_user;

    let formattedKey = formatKey(request?.key, request?.type);

    formattedKey =
      request.type === "PHONE" ? "+55" + formattedKey : formattedKey;

    try {
      const { data } = await apis.getKey(formattedKey, accountId, userId);
      const document = isValidCPF(data.cpfCnpj)
        ? user?.profiles?.Customers?.[0].cpf
        : user?.profiles?.Customers?.[0].cnpj;

      if (data.cpfCnpj !== document) {
        return "OWNERSHIP";
      } else {
        return "PORTABILITY";
      }
    } catch (error) {
      console.error(error);
    }
  }, [request, user?.login?.guid_user, user?.profiles?.Customers]);

  const onSendToken = async () => {
    const formattedKeyTypes: Partial<Record<typeof keyType, string>> = {
      "E-mail": "email",
      Celular: "cellPhoneNumber",
    };

    const formattedPixKey =
      keyType === "Celular" ? formatPhoneNumber(newPixKey) : newPixKey;

    await API.pix.post("/api/v1/pix/token/create", {
      tokenTypeId: "63D07325-4980-4ED9-9F6B-D7CFC985BA3F",
      [formattedKeyTypes[keyType]]: formattedPixKey,
    });
  };

  const goToToken = useCallback(async () => {
    if (isExternalCode) {
      onSendToken();
    }

    setDrawer({
      title: isExternalCode ? keyType : "Token",
      content: (
        <TokenSheet
          isExternalCode={isExternalCode}
          keyType={keyType}
          newPixKey={newPixKey}
          goToNextStep={goToSucessPage}
          onSendToken={onSendToken}
          openClaimDrawer={openClaimDrawer}
        />
      ),
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, navigate, routes.token, isExternalCode, keyType, newPixKey]);

  const formattedPixKey =
    keyType === "Celular" ? `+55${formatPhoneNumber(newPixKey)}` : newPixKey;

  const createTokenRequestClaim = useCallback(async () => {
    await API.pix.post("/api/v1/pix/Claims", {
      type: formattedKeyTypes[keyType],
      key: formattedPixKey,
    });

    goToSucessPage();
  }, [keyType, formattedPixKey]);

  const openClaimDrawer = useCallback(async () => {
    const claimType = "OWNERSHIP";

    setDrawer({
      title: `${
        claimType === "OWNERSHIP" ? "Reivindicação" : "Portabilidade"
      } de chave Pix`,
      open: true,
      content: (
        <S.WrapperClaimDrawer>
          <div>
            <Icon name="MoneyPix" />
            <p>
              <span>{checkPixKey(newPixKey)}</span>
              <span>{formattedPixKey}</span>
            </p>
          </div>
          {claimType === "OWNERSHIP" ? (
            <p>
              Chave Pix já cadastrada por outra pessoa, deseja iniciar processo
              de reivindicação?
            </p>
          ) : (
            <p>
              Chave Pix já está cadastrada em outra instituição, deseja fazer
              portabilidade?
            </p>
          )}
          <Button onClick={createTokenRequestClaim}>
            {claimType === "OWNERSHIP"
              ? "Iniciar Reivindicação"
              : "Fazer Portabilidade"}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              goToSelectKeys();
              setDrawer(null);
            }}
          >
            Cadastrar outra
          </Button>
        </S.WrapperClaimDrawer>
      ),
    });
  }, [checkPixKey, createTokenRequestClaim, newPixKey, formattedPixKey]);

  const closeModal = () => setModal({ ...modal, open: false });

  const closeDrawer = () => {
    setDrawer((state) => ({
      ...state,
      open: false,
    }));
  };

  const openImageQrModal = () => {
    closeDrawer();

    setModal({
      open: true,
      content: <ContentQR defaultKey={defaultKey} closeModal={closeModal} />,
    });
  };

  const copyDefaultKey = () => navigator.clipboard.writeText(defaultKey.key);

  const openSharedDrawer = () => {
    if (isMobile) {
      setDrawer({
        title: "Compartilhar QR Code",
        open: true,
        content: (
          <SharedDrawer
            openImageQrModal={openImageQrModal}
            copyDefaultKey={copyDefaultKey}
          />
        ),
      });
    } else {
      print({
        // @ts-ignore
        content: <PrintableQRCode defaultKey={defaultKey} />,
      });
    }
  };

  const backToMyKeys = useCallback(() => {
    navigate(routes.pixMyKeys);
  }, [navigate, routes.pixMyKeys]);

  const backToMyKeysSucess = () => {
    setRequest({});
    setTokenData({});
    backToMyKeys();
  };

  const backToInsertKey = () => {
    navigate(routes.pixMyKeysNew);
  };

  const goToSelectKeys = () => {
    setCurrentPage(PAGES.SELECT_KEY);
  };

  const goToInsertKey = () => {
    setCurrentPage(PAGES.INSERT_KEY);
  };

  const goToSucessPage = () => {
    setDrawer(null);
    setCurrentPage(PAGES.SUCCESS_SCREEN);
  };

  useEffect(() => {
    if (!isMobile) return;
    setHeader({
      title: "Minhas chaves Pix",
      show: isMobile,
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: backToMyKeys,
      },
    });
  }, [backToMyKeys, isMobile]);

  const createTokenRequest = async () => {
    const cleanKey =
      request?.type === "PHONE" || request?.type === "EMAIL"
        ? request.type === "PHONE"
          ? request?.key?.replace(/\D/g, "")
          : request?.key
        : undefined;

    const path = isMobile ? routes.pixMyKeysNew : `${routes.pix}?newKey=open`;

    setTokenData({
      request: {
        guid_token_type: request?.claim
          ? tokenRequestClaimGuid
          : tokenRequestGuid,
        guid_user: user?.login?.guid_user,
        customKey: request?.claim ? undefined : cleanKey,
        path,
        header: {
          title: request?.title,
          leftIcon: {
            name: "ArrowArrowNoLineLeft",
            onClick: backToInsertKey,
          },
        },
      },
    });

    goToToken();
  };

  useEffect(() => {
    const setClaim = async () => {
      const claimType = await validateOwnershipOrPortability();
      setClaimType(claimType);
    };
    setClaim();
  }, [validateOwnershipOrPortability]);

  const components = {
    [PAGES.SUCCESS_SCREEN]: (
      <SuccessScreen
        claimType={claimType}
        goToSelectKeys={goToSelectKeys}
        openSharedDrawer={openSharedDrawer}
        backToMyKeysSucess={backToMyKeysSucess}
      />
    ),
    [PAGES.INSERT_KEY]: (
      <InsertKey
        fetchState={fetchState}
        backToMyKeysSucess={backToMyKeysSucess}
        createTokenRequest={createTokenRequest}
      />
    ),
    [PAGES.SELECT_KEY]: (
      <SelectKey
        keys={keys}
        goToInsertKey={goToInsertKey}
        goToToken={goToToken}
      />
    ),
  };

  return (
    <>
      {components[currentPage]}
      {modal.open ? modal?.content : null}
      <Drawer
        open={drawer?.open ?? false}
        title={drawer?.title}
        onDismiss={() => {
          closeDrawer();
          drawer.onClose?.();
        }}
        position="right"
      >
        {drawer?.content}
      </Drawer>
    </>
  );
};

export default NewKey;
