import styled, { css } from "styled-components";

export const BreadcrumbWrapper = styled.div`
  ${({ theme }) => {
    const { spacings } = theme;
    return css`
      padding: ${spacings.stack.sm} ${spacings.inline.xs} 0px;
    `;
  }}
`;

export const WrapperKey = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 56px);
    gap: 16px;
    padding: 0 16px;

    ${theme.breakpoints.desktop(`
    padding: 0;
  `)}

    & > * {
      font-family: "Inter";
      font-style: normal;
    }
    & > button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;

      & > p {
        & > span {
          font-size: ${theme.fontSizes.x2s} !important;
        }
      }

      & > .wrapperDetailTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        & > h2 {
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
        }
      }

      & > p {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        & > span {
          font-weight: 500;
          font-size: 10px;
          line-height: 150%;
        }
      }
      & > div {
        display: flex;
        flex-direction: column;
        gap: 14px;
        & > p {
          display: flex;
          width: 100%;
          justify-content: space-between;
          font-weight: 500;
          color: ${theme.palette.neutral.darkest};
          font-size: ${theme.fontSizes.x2s};
          line-height: 150%;
          & > span.status {
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            color: ${theme.palette.primary.main};
          }
          & > span {
            font-size: 14px;
            color: ${theme.palette.neutral.darker};
          }
        }
      }
    }
  `}
`;

export const IconContainer = styled.div`
  width: 24px;

  & > button {
    background: transparent;
    border: none;
    cursor: pointer;

    width: 100%;
    height: 100%;
  }
`;

export const SucessScreen = styled.div`
  ${({ theme }) => css`
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 56px);
    & > * {
      font-family: "Inter";
      font-style: normal;
    }
    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      & > img {
        width: 165px;
        height: 165px;
      }

      & > hr {
        width: 100%;
        background-color: ${theme.palette.neutral.light};
      }

      & > svg {
        display: block;
        width: 177.38px;
        height: auto;
      }
    }

    & > div.wrapperButtons {
      display: flex;
      flex-direction: row;
      gap: 8px;
      width: 100%;
      & > button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
  `}
`;

export const WapperDrawerDelete = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 430px;

    & > * {
      font-family: "Inter";
      font-style: normal;
    }
    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 170%;
      color: ${theme.palette.neutral.darker};
    }
    & > div {
      display: flex;
      gap: 15px;
      align-items: center;
      & > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        flex-direction: column;
        & > span {
          width: 100%;
          font-weight: 500;
          font-size: 10px;
          line-height: 150%;
        }
      }
    }
    & svg {
      width: 24px;
      height: 24px;
      fill: ${theme.palette.neutral.darkest};
    }
  `}
`;

export const ModalWrapper = styled.div`
  background-color: blue;

  & > div {
    & > main {
      & > div:nth-child(3) {
        word-break: normal;
      }
    }
  }
`;

export const Main = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.desktop(`
    padding: 32px 0 0 0;
    height: 100%;
  `)}
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100svh - 56px);
    gap: 16px;

    & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &.WrapperButton{
      & >button{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    
    & > * {
      font-family: "Inter";
      font-style: normal;
    }
    & > nav.claim {
      .keyTypeIcon {
        min-width: 24px;
        width: 24px;
        height: 24px;
        min-height: 24px;
        
        fill: ${theme.palette.neutral.darkest};
      }
    }
    & > h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: ${theme.palette.neutral.darkest};
    }
    & > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 170%;
      color: ${theme.palette.neutral.darker};
    }
    & > nav > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      & > div {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        & > p {
          display: flex;
          flex-direction: column;
          & > span {
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
          }
        }
        & > p :nth-child(1) {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
        }
        & > svg {
        width: 24px;
        height: 24px;
        fill: ${theme.palette.neutral.darkest};
    }    
  }
  }
  `}
`;
